<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="mask-company animated faster fadeIn">
        <div class="space-between">
          <div class="text-main">{{title}}</div>
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <slot></slot>
        <div class="row-center mask-btn" v-if="phoneShow">
          <div class="text-blue flex-center pointer" @click="hide">取消</div>
          <div
            class="text-white bg-blue flex-center pointer"
            @click="submitClick"
          >
            {{contentText}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        contentText:{
            type:String,
            default:"保存"
        },
        title:{
          type:String,
          default:'添加手机号'
        },
        phoneShow:{
          type:Boolean,
          default:true
        }
    },
  data() {
    return {
      status: false,
    };
  },
  methods: {
    // 显示
    show() {
      this.status = true;
    },
    // 隐藏
    hide() {
      this.status = false;
    },
    // 确定
    submitClick() {
      this.hide();
      this.$emit("submitClick");
    },
  },
};
</script>

<style lang='less' scoped>
.mask-company {
  width: 30rem;
  background: #ffffff;
  border-radius: 3px;
  padding: 1.3125rem 1.3125rem 1.5rem 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  .mask-btn {
    justify-content: flex-end;
    div {
      width: 5.625rem;
      height: 2.125rem;
      margin-left: 0.875rem;
      font-size: 0.875rem;
    }
    div:first-child {
      border: 1px solid #126bf9;
    }
  }
}
</style>
